import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';

import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import masterDataService from '../../service/masterData.service';
import ProductionReports from './ProductionReports';
import productionService from '../../service/production.service';
import Yup from '../../utils/yup';
import useUsers from '../../context/UsersContext';
import { useLoader } from '../../provider/LoaderProvider';

import FormFields from './FormFields';
import FormTabs from './FormTabs';
import { FormHeaderUI, FormButtonPanelUI, DeleteDialogUI } from '../Interface';

export const ProductionForm = (_props) => {
  const { user, getUser } = useUsers();
  const { showLoader, hideLoader } = useLoader();
  const [refetchTable, setRefetchTable] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [productionList, setProductionList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [newFiles, setNewFiles] = useState([]);

  const [defaultValues, setDefaultValues] = useState({});
  const [autocompleteData, setAutocompleteData] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState({});
  const [initValues, setInitValues] = useState({});

  let { id } = useParams();

  useEffect(() => {
    getUser();

    initDefaultValues();

    const def = {
      workingNumber: '',
      customerOrdNumber: '',
      projectId: null,
      articleNumber: '',
      articleName: '',
      productionQuantity: 1,
      me: null,
      startDate: dayjs(new Date()),
      endDate: dayjs(new Date()).add(1, 'hour'),
      statusId: null,
      companyId: null,
      description: '',
    };

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          statusId: autocompleteData?.status?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          status: autocompleteData?.status?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    setDefaultValues((prev) => {
      return { ...prev, userId: user.userId };
    });

    setDefaultSelectedValues((prev) => {
      return {
        ...prev,
        user: user,
      };
    });
  }, [user]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        setProductionList(selectedValues?.productionOperation ?? []);
        setFileList(selectedValues?.productionAttachment ?? []);
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    showLoader();
    masterDataService
      .statusToForm(id)
      .then((data) => {
        const aData = {
          status: data,
        };
        setAutocompleteData(aData);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const validationSchema = Yup.object().shape({
    workingNumber: Yup.string().required(),
    customerOrdNumber: Yup.string(),
    projectId: Yup.string().nullable(),
    articleNumber: Yup.string().required(),
    articleName: Yup.string().required(),
    productionQuantity: Yup.number().nullable().positive(),
    me: Yup.string().nullable().required(),
    startDate: Yup.string(),
    endDate: Yup.string(),
    statusId: Yup.string().required(),
    companyId: Yup.string().nullable(),
    description: Yup.string(),
  });

  const valuesToForm = () => {
    const {
      workingNumber,
      customerOrdNumber,
      projectId,
      articleNumber,
      articleName,
      productionQuantity,
      me,
      startDate,
      endDate,
      statusId,
      userId,
      companyId,
      description,
    } = selectedValues;

    setInitValues({
      workingNumber,
      customerOrdNumber,
      projectId,
      articleNumber,
      articleName,
      productionQuantity,
      me,
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      statusId,
      userId,
      companyId,
      description,
    });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const handleFileUpload = async (files, id) => {
    try {
      /*for (const item of files) {
        item.append('SimpleTaskId', id);
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/upload-endpoint`, {
            method: 'POST',
            body: item,
          });

          if (response.ok) {
            const data = await response.json();
          } else {
            console.error('Hiba történt a feltöltés során:', response.statusText);
          }
        } catch (error) {
          console.error('Hiba történt a fetch során:', error);
        }
      }*/
    } catch (error) {
      //console.error('Hiba történt:', error);
    }
    setNewFiles([]);
    //setFileList([]);
  };

  const updateObject = (values) => {
    const updData = {
      ...values,
      startDate: dayjs(values.startDate).toISOString(),
      endDate: dayjs(values.endDate).toISOString(),
    };

    productionService.updateProduction(updData, selectedValues.productionId).then((_production) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setRefetchTable(true);
      handleFileUpload(newFiles, selectedValues.productionId);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = (values) => {
    showLoader();
    const insertObject = {
      ...values,
      startDate: dayjs(values.startDate).toISOString(),
      endDate: dayjs(values.endDate).toISOString(),
    };

    productionService
      .createProduction(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
        handleFileUpload(newFiles, data.productionId);
        setAddField(true);
        setViewField(true);
        setInitValues({});
      })

      .finally(() => {
        hideLoader();
      });
  };

  const handleRemoveElement = async () => {
    productionService.deleteProduction(selectedValues.productionId).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultSelectedValues);
    });
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-4 grid-rows-[auto_1fr] gap-y-4 xl:gap-4 bg-gray-100 p-4">
      <div className="col-span-3 md:col-span-2 w-full overflow-x-auto overflow-y-auto">
        <div className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg">
          <FormHeaderUI
            addClick={() => {
              setSelectedValues(defaultSelectedValues);
              setInitValues(defaultValues);
              setViewField(false);
              setAddField(false);
              setProductionList([]);
              setFileList([]);
            }}
            addDisabled={!viewField}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled}
            copyClick={() => {
              setViewField(false);
              setAddField(false);
            }}
            copyDisabled={!viewField || operatorsDisabled}
          />
          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ isSubmitting, values, setFieldValue, errors, validateField, resetForm }) => (
              <Form>
                <FormFields
                  viewField={viewField}
                  submenuId={id}
                  selectedValues={selectedValues}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  validateField={validateField}
                />

                <FormTabs
                  values={values}
                  errors={errors}
                  setRefetchTable={setRefetchTable}
                  addField={addField}
                  productionId={selectedValues?.productionId}
                  disabled={viewField}
                  productionList={productionList}
                  setProductionList={setProductionList}
                  fileList={fileList}
                  setFileList={setFileList}
                  newFiles={newFiles}
                  setNewFiles={setNewFiles}
                  formId={'description'}
                  value={initValues.description}
                />

                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      setNewFiles([]);
                      resetForm();
                    }}
                    cancelDisabled={isSubmitting}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />

      <ProductionReports
        refetch={{ refetchTable, setRefetchTable }}
        values={{ selectedValues, setSelectedValues }}
        viewField={viewField}
        operatorsDisabled={operatorsDisabled}
      />
    </div>
  );
};
