import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'production';

const productionService = {
  getProductions: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/productions`,
      data,
    });
  },

  getMyTodoStatus: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/mytodo/status`,
    });
  },

  createProduction: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateProduction: (data, productionId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${productionId}`,
      data,
    });
  },

  deleteProduction: (productionId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${productionId}`,
    });
  },

  createProductionOperation: (data, productionId) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/productionOperation/${productionId}`,
      data,
    });
  },

  updateProductionOperation: (data, prodOperationId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/productionOperation/${prodOperationId}`,
      data,
    });
  },

  deleteProductionOperation: (prodOperationId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/productionOperation/${prodOperationId}`,
    });
  },

  getProductionInfo: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/productionreport/search`,
      data,
    });
  },

  createProductionReport: (prodOperationId, data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/productionreport/${prodOperationId}`,
      data,
    });
  },

  getProductionReports: (prodOperationId, data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/productionreports/${prodOperationId}`,
      data,
    });
  },

  getProductionById: (simpleTaskId) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/${simpleTaskId}`,
    });
  },
};

export default productionService;
