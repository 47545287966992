import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'simpletask';

const simpletaskService = {
  getTasks: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/tasks`,
      data,
    });
  },

  getTaskById: (simpleTaskId) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/${simpleTaskId}`,
    });
  },

  getMyTodoPriority: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/mytodo/priority`,
    });
  },

  getMyTodoStatus: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/mytodo/status`,
    });
  },

  updateStatus: (data) => {
    return request({
      method: Methods.PATCH,
      resource: `${baseURL}/status`,
      data,
    });
  },

  createSimpleTask: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateSimpleTask: (data, simpleTaskId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${simpleTaskId}`,
      data,
    });
  },

  deleteSimpleTask: (simpleTaskId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${simpleTaskId}`,
    });
  },

  addRespPersonToList: (data, simpleTaskId) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/respPersonList/${simpleTaskId}`,
      data,
    });
  },

  deleteRespPersonFromList: (personListId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/respPersonList/${personListId}`,
    });
  },

  createTaskReport: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/taskReport`,
      data,
    });
  },
};

export default simpletaskService;
