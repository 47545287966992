import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'projectgroup';

const projectGroupService = {
  getProjectGroup: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/projectgroups`,
      data,
    });
  },

  createProjectGroup: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateProjectGroup: (data, projectGroupStatusId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${projectGroupStatusId}`,
      data,
    });
  },

  createProjectGroupItem: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/projectGroupItem`,
      data,
    });
  },

  deleteProjectGroupItem: (projectGroupItemId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/projectGroupItem/${projectGroupItemId}`,
    });
  },

  updateProjectGroupItem: (data, projectGroupStatusId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/projectGroupItem/${projectGroupStatusId}`,
      data,
    });
  },

  updateDefaultValue: (id, itemId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/defaultValue/${id}/${itemId}`,
    });
  },
};

export default projectGroupService;
