import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'taskgantt';

const taskGanttService = {
  getTask: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/task`,
      data,
    });
  },

  getProductionResources: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/productionresources`,
      data,
    });
  },

  getMachines: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/machines`,
    });
  },

  updateTask: (data, simpleTaskId) => {
    return request({
      method: Methods.PATCH,
      resource: `${baseURL}/task/${simpleTaskId}`,
      data,
    });
  },

  updateProduction: (data, prodOperationId) => {
    return request({
      method: Methods.PATCH,
      resource: `${baseURL}/production/${prodOperationId}`,
      data,
    });
  },

  getActiveTimeByRange: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/activeTimes`,
      data,
    });
  },
};
export default taskGanttService;
