import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Form, Formik } from 'formik';
import Yup from '../../utils/yup';
import simpletaskService from '../../service/simpletask.service';
import masterDataService from '../../service/masterData.service';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';

import useMenus from '../../context/MenuContext';
import { useLoader } from '../../provider/LoaderProvider';
import FormFields from './FormFields';
import { FormButtonPanelUI } from '../Interface';
import FormTabs from './FormTabs';

export const TaskReportForm = ({ selectedTask, setShowDialog, onReportAdd }) => {
  const { menus, getMenus, getMenuSubMenuId } = useMenus();
  const { showLoader, hideLoader } = useLoader();
  const [submenu, setSubmenu] = useState(null);
  const [selectedValues, setSelectedValues] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [autocompleteData, setAutocompleteData] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [initValues, setInitValues] = useState({});

  useEffect(() => {
    getMenus();

    const def = {
      simpleTaskId: null,
      timeSpent: '',
      me: '',
      typeId: null,
      description: '',
    };

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    setSubmenu(getMenuSubMenuId('simpleTask'));
  }, [menus]);

  useEffect(() => {
    if (submenu) initDefaultValues();
  }, [submenu]);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          typeId: autocompleteData?.type?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          type: autocompleteData?.type?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  const initDefaultValues = async () => {
    const [typeData] = await Promise.all([masterDataService.typeToForm(submenu)]);
    const aData = {
      type: typeData,
    };
    setAutocompleteData(aData);
  };

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedTask)) {
        valuesToForm();
      } else {
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const validationSchema = Yup.object().shape({
    simpleTaskId: Yup.string().required(),
    timeSpent: Yup.number().required(),
    me: Yup.string(),
    typeId: Yup.string(),
    description: Yup.string(),
  });

  const valuesToForm = () => {
    if (selectedTask) {
      const { simpleTaskId, timeSpent, me, typeId, description } = selectedTask;

      setInitValues({
        simpleTaskId,
        timeSpent,
        me,
        typeId: typeId ?? selectedTask.simpleTaskTypeId,
        description,
      });
    } else {
      setInitValues(defaultValues);
    }
  };

  const handleSubmit = (values) => {
    handleInsertObject(values);
  };

  const handleInsertObject = (values) => {
    showLoader();
    const insertObject = {
      ...values,
      me: 'h',
    };

    simpletaskService
      .createTaskReport(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setInitValues({});
        setSelectedValues(defaultValues);
        if (selectedTask && onReportAdd) {
          setShowDialog(false);
          onReportAdd(data);
        }
      })
      .finally(() => {
        hideLoader();
      });
  };

  return (
    <div className={selectedTask ? '' : 'bg-gray-100 h-full w-full pt-32 fixed top-0 left-0'}>
      <div className={selectedTask ? 'mt-5' : 'h-auto bg-white py-5 px-5 w-96  mx-auto rounded-lg'}>
        <Formik
          initialValues={initValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting, values, setFieldValue, errors, validateField }) => (
            <Form>
              <FormFields
                selectedTask={selectedTask}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                validateField={validateField}
                submenu={submenu}
              />

              <FormTabs values={values} errors={errors} formId={'description'} value={initValues.description} />

              <FormButtonPanelUI cancelDisabled={isSubmitting} />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
