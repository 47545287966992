import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';

import appService from '../../service/app.service';
import simpletaskService from '../../service/simpletask.service';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';

import SimpleTaskReports from './SimpleTaskReports';
import FormFields from './FormFields';
import FormTabs from './FormTabs';
import { FormHeaderUI, FormButtonPanelUI, DeleteDialogUI, DialogUI } from '../Interface';
import Yup from '../../utils/yup';
import masterDataService from '../../service/masterData.service';
import { TaskReportForm } from '../TaskReport/TaskReportForm';

export const SimpleTaskForm = (_props) => {
  const [refetchTable, setRefetchTable] = useState(true);
  const [owner, setOwner] = useState({});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [memberList, setMemberList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [newMembers, setNewMembers] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [reportList, setReportList] = useState([]);

  const [defaultValues, setDefaultValues] = useState({});
  const [autocompleteData, setAutocompleteData] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState({});
  const [initValues, setInitValues] = useState({});

  let { id } = useParams();

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('user');
    const userObj = JSON.parse(isAuthenticated);
    setOwner(userObj);

    initDefaultValues();

    const def = {
      simpleTaskName: '',
      startDate: dayjs(new Date()),
      endDate: dayjs(new Date()).add(1, 'hour'),
      duration: 1,
      preparedness: '',
      machineId: null,
      projectId: null,
      simpleTaskTypeId: null,
      userId: null,
      resposiblePersonId: null,
      statusId: null,
      priorId: null,
      simpleTaskDescription: '',
    };

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          simpleTaskTypeId: autocompleteData?.type?.defaultValue ?? null,
          statusId: autocompleteData?.status?.defaultValue ?? null,
          priorId: autocompleteData?.priority?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          type: autocompleteData?.type?.defItem,
          status: autocompleteData?.status?.defItem,
          priority: autocompleteData?.priority?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    // ide kell a defaultValues függőségeit pakolni, az effectet kibővíteni
    // Tölteni kell a default értékekhez kapcsolódó leírókkal
    setDefaultValues((prev) => {
      return { ...prev, userId: owner.userId };
    });

    setDefaultSelectedValues((prev) => {
      return {
        ...prev,
        user: owner,
      };
    });
  }, [owner]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        setMemberList(selectedValues?.simpleTaskRespPersonlist ?? []);
        setFileList(selectedValues?.simpleTaskAttachment ?? []);
        setReportList(selectedValues?.taskReports ?? []);
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .typeToForm(id)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .statusToForm(id)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .priorityToForm(id)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          type: resp[0],
          status: resp[1],
          priority: resp[2],
        };
        setAutocompleteData(aData);
      })
      .finally(() => {
        //hideLoader()
      });
  };

  const validationSchema = Yup.object().shape({
    simpleTaskName: Yup.string().required(),
    startDate: Yup.string().required(),
    endDate: Yup.string().required(),
    duration: Yup.number().positive(),
    preparedness: Yup.number().nullable().positive(),
    machineId: Yup.string().nullable(),
    projectId: Yup.string().nullable(),
    simpleTaskTypeId: Yup.string().required(),
    userId: Yup.string().required(),
    resposiblePersonId: Yup.string().nullable(),
    statusId: Yup.string().required(),
    priorId: Yup.string().required(),
    simpleTaskDescription: Yup.string(),
  });

  const valuesToForm = () => {
    const {
      simpleTaskName,
      startDate,
      endDate,
      duration,
      preparedness,
      machineId,
      projectId,
      simpleTaskTypeId,
      userId,
      resposiblePersonId,
      statusId,
      priorId,
      simpleTaskDescription,
    } = selectedValues;

    setInitValues({
      simpleTaskName,
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      duration,
      preparedness,
      machineId,
      projectId,
      simpleTaskTypeId,
      userId,
      resposiblePersonId,
      statusId,
      priorId,
      simpleTaskDescription,
    });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const handleAddTaskMembers = async (taskMembers, insertId) => {
    const tableName = 'SimpleTaskRespPersonlist';
    const id = 'respPersonSimpleTaskId';

    for (const item of taskMembers) {
      const insertObject = {
        userId: item.userId,
        SimpleTaskId: item.simpleTaskId ?? insertId,
        roleName: item.roleName,
      };

      const insData = { tableName, id, data: insertObject };

      appService
        .addItem(insData)
        .then(() => {
          addNotification({
            content: 'Sikeres mentés!',
            type: NotificationType.SUCCESS,
          });
        })
        .finally(() => {});
    }

    setNewMembers([]);
  };

  const handleFileUpload = async (files, id) => {
    try {
      /*for (const item of files) {
        item.append('SimpleTaskId', id);
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/upload-endpoint`, {
            method: 'POST',
            body: item,
          });

          if (response.ok) {
            const data = await response.json();
          } else {
            console.error('Hiba történt a feltöltés során:', response.statusText);
          }
        } catch (error) {
          console.error('Hiba történt a fetch során:', error);
        }
      }*/
    } catch (error) {
      //console.error('Hiba történt:', error);
    }
    setNewFiles([]);
    //setFileList([]);
  };

  const updateObject = (values) => {
    const updData = {
      ...values,
      duration: parseFloat(values.duration),
      preparedness: parseFloat(values.preparedness),
      startDate: dayjs(values.startDate).toISOString(),
      endDate: dayjs(values.endDate).toISOString(),
    };

    simpletaskService.updateSimpleTask(updData, selectedValues.simpleTaskId).then((_simpleTask) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setRefetchTable(true);
      handleAddTaskMembers(newMembers, 0);
      handleFileUpload(newFiles, selectedValues.simpleTaskId);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = (values) => {
    const insertObject = {
      ...values,
      duration: parseFloat(values.duration),
      preparedness: parseFloat(values.preparedness),
      startDate: dayjs(values.startDate).toISOString(),
      endDate: dayjs(values.endDate).toISOString(),
    };

    simpletaskService
      .createSimpleTask(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
        // @TODO ezeket tudjuk az első inserttel is kezelni!!!
        handleAddTaskMembers(newMembers, data.simpleTaskId);
        handleFileUpload(newFiles, data.simpleTaskId);
        setAddField(true);
        setViewField(true);
        setInitValues({});
      })

      .finally(() => {
        //@TODO loader
      });
  };

  const handleRemoveElement = async () => {
    simpletaskService.deleteSimpleTask(selectedValues.simpleTaskId).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultSelectedValues);
    });
  };

  const handleAddReport = (newReport) => {
    newReport.taskType = selectedValues.taskType;
    setReportList((prevList) => [...prevList, newReport]);
    setRefetchTable(true);
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-6 grid-rows-[auto_1fr] gap-y-4 xl:gap-4 bg-gray-100 p-4">
      <div className="col-span-4 md:col-span-2 w-full overflow-x-auto overflow-y-auto">
        <div className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg">
          <FormHeaderUI
            addClick={() => {
              setSelectedValues(defaultSelectedValues);
              setInitValues(defaultValues);
              setViewField(false);
              setAddField(false);
              setMemberList([]);
              setFileList([]);
            }}
            addDisabled={!viewField}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled}
            copyClick={() => {
              setViewField(false);
              setAddField(false);
            }}
            copyDisabled={!viewField || operatorsDisabled}
            reportClick={() => {
              setShowDialog(true);
            }}
            reportDisabled={!viewField || operatorsDisabled}
          />
          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
            //validateOnMount={false}
          >
            {({ isSubmitting, values, setFieldValue, errors, validateField, resetForm }) => (
              <Form>
                <FormFields
                  viewField={viewField}
                  submenuId={id}
                  selectedValues={selectedValues}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  validateField={validateField}
                />

                <FormTabs
                  values={values}
                  errors={errors}
                  setRefetchTable={setRefetchTable}
                  addField={addField}
                  //Azonosító, hogy melyik form-hoz adjuk hozzá az elemet
                  simpleTaskId={selectedValues?.simpleTaskId}
                  disabled={viewField}
                  //További felelősökhoz tartozó részek
                  memberList={memberList}
                  setMemberList={setMemberList}
                  reportList={reportList}
                  // Drag and Drop tartozó formrészek
                  fileList={fileList}
                  setFileList={setFileList}
                  newFiles={newFiles}
                  setNewFiles={setNewFiles}
                  //Leírás formrészhez szükséges mezők
                  formId={'simpleTaskDescription'}
                  value={initValues.simpleTaskDescription}
                />

                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      setNewMembers([]);
                      setNewFiles([]);
                      resetForm();
                    }}
                    cancelDisabled={isSubmitting}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />

      <DialogUI
        open={showDialog}
        headerContent={'Feladat lejelentő'}
        fullWidth={true}
        onClose={() => setShowDialog(false)}
      >
        <TaskReportForm selectedTask={selectedValues} setShowDialog={setShowDialog} onReportAdd={handleAddReport} />
      </DialogUI>

      <SimpleTaskReports
        refetch={{ refetchTable, setRefetchTable }}
        values={{ selectedValues, setSelectedValues }}
        viewField={viewField}
        operatorsDisabled={operatorsDisabled}
      />
    </div>
  );
};
